import request from '../utils/request'
import baseUrl from './baseUrl'
export const tableList = (data) => request({
    url: baseUrl + '/balanceInfo/selectForShop',
    method: 'POST',
    data
  })
  
  export const createdForm = (data) => request({
    url: baseUrl + '/balanceInfo/createShopInfoExcel',
    method: 'POST',
    responseType:'blob',
    data
  })