<template>
  <div class="productList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>店长分销流水</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="search_box">
      <span>姓名：</span>
      <el-input
        style="width: 200px"
        v-model="formData.trueName"
        placeholder="请输入姓名"
      />
      <span>手机号：</span>
      <el-input
        style="width: 200px"
        v-model="formData.phone"
        placeholder="请输入手机号"
      />
      <span>会员等级：</span>
      <el-select v-model="formData.changeType" placeholder="请选择">
        <el-option
          v-for="(item,index) in changeType"
          :key="index"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <br />
      <br />
      <br />

      <el-button type="primary" class="btn" @click="getTableList()"
        >查询</el-button
      >
      <el-button type="primary" class="btn" @click="createForm()"
        >生成报表</el-button
      >
    </div>
    <br />
    <span>注册时间：</span>
    <el-date-picker
      @change="onChange"
      v-model="addTime"
      type="daterange"
      align="right"
      unlink-panels
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      value-format="yyyy-MM-dd"
      :picker-options="pickerOptions"
    >
    </el-date-picker>
    <el-table
      border
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="ubId" label="用户ID"> </el-table-column>
      <el-table-column prop="changeType" label="交易类型"> </el-table-column>
      <el-table-column prop="userName" label="姓名" width="120">
      </el-table-column>
      <el-table-column prop="phone" label="手机号"> </el-table-column>
      <el-table-column prop="addTime" label="交易时间" width="120">
      </el-table-column>
      <el-table-column prop="endFee" label="剩余金额" width="120">
      </el-table-column>
      <el-table-column prop="curFee" label="期初金额"> </el-table-column>
      <el-table-column prop="shopName" label="店铺名"> </el-table-column>
      <el-table-column prop="nickName" label="用户名"> </el-table-column>
      <!-- <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" style="margin-left: 0.625rem;" 
            @click="$router.push(`/shopUserList?fatherId=${scope.row.userId}`)" 
          >
            查看下一级
          </el-button>
          <br/>
          <br/>
          <el-button type="primary" size="mini" style="margin-left: 0.625rem;" 
            @click="isShowDialgo(scope.row.userId,scope.row.label)" 
          >
            标签
          </el-button>
        </template>
			</el-table-column> -->
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="formData.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="formData.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="formData.total"
    >
    </el-pagination>
    <!-- 弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      title="备注"
      :visible.sync="isShowLabel"
      width="50%"
    >
      <el-form ref="form" label-width="100px" class="grade_list">
        <el-form-item label="备注">
          <el-input type="textarea" :rows="4" v-model="remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hiddenLabel">取 消</el-button>
        <el-button type="primary" @click="changeLabel(userId)">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import productBox from "./productBox";
import {
  cateAllApi,
  materialList,
  materialAdd,
  materialDelete,
  materialDetail,
  changeSort,
} from "../../api/shopMaterial.js";
import {
  tableList,
  createdForm, //下载报表
} from "../../api/shopOwner";
export default {
  name: "shopUserList",
  components: {
    productBox,
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      options: [],
      changeType: [
        { value: 0, label: "全部" },
        { value: 1, label: "订单消费" },
        { value: 2, label: "充值" },
        { value: 3, label: "万小店收益" },
        { value: 4, label: "提现" },
        { value: 5, label: "管理员增加" },
        { value: 6, label: "退款" },
        { value: 7, label: "管理员减少" },
        { value: 8, label: "拒绝提现返回" },
      ],
      formData: {
        phone: "",
        trueName: "",
        shopName: "",
        nickName: "",
        changeType: "",
        total: 0,
        pageSize: 10,
        currentPage: 1,
        beginTime: "", //注册时间开始
        endTime: "", //注册时间结束
      },
      downLoadParmas: {
        phone: "",
        trueName: "",
        fatherId: 0,
        // total: 0,
        // pageSize: 10,
        // currentPage: 1,
        beginTime: "", //注册时间开始
        endTime: "", //注册时间结束
        userId: "",
      },
      editForm: {
        status: "",
        remark: "",
      },
      tableData: [],
      multipleSelection: [],
      dialogVisible: false,
      dialogVisible2: false,
      allLevelList: [],
      addTime: [],
      isShowLabel: false,
      remark: "",
      label: "",
      userId: "",
    };
  },
  watch: {
    $route(to, before) {
      // console.log('to', to)
      // console.log('before', before)
      if (to.path == "/shopUserList") {
        this.formData.fatherId = to.query.fatherId || 0;
        this.getTableList(); // 获取表格数据
      }
    },
  },
  created() {},
  mounted() {
    this.getTableList(); // 获取表格数据
  },
  methods: {
    isShowDialgo(e, e1) {
      this.isShowLabel = true;
      this.userId = e;
      this.remark = e1;
    },
    hiddenLabel() {
      this.isShowLabel = false;
    },
    onChange(value) {
      console.log(value);
      if (value) {
        this.formData.beginTime = value[0];
        this.formData.endTime = value[1];
      } else {
        this.formData.beginTime = null;
        this.formData.endTime = null;
      }
    },

    onSelectChange(row) {
      this.$confirm("是否更改等级?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await modifyVipLevel({
            userId: row.userId,
            levelId: row.levelId,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getTableList();
          } else {
            this.$message({
              type: "warning",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.getTableList();
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    goBack() {
      if (this.formData.fatherId > 0) {
        this.$router.go(-1);
      }
    },
    async getTableList() {
      const { data } = await tableList(this.formData);
      this.tableData = data.list;
      this.formData.total = data.pagination.total;
      this.formData.pageSize = data.pagination.pageSize;
      this.formData.currentPage = data.pagination.current;
    },
    //下载报表
    async createForm() {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      let date = +new Date();
      const { data } = await createdForm({
        phone: this.formData.phone,
        trueName: this.formData.trueName,
        fatherId: this.formData.fatherId,
        levelId: this.formData.levelId,
        userId: this.formData.userId,
        //  beginTime:this.formData.beginTime,
        //  endTime:this.formData.endTime,
      });
      let url = window.URL.createObjectURL(data); //转换文件流为URL
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", `万旅网店长流水报表_日期_${date}.xls`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      loading.close()
    },
    //修改备注标签
    async changeLabel(userId) {
      const { data } = await changeOwnerLabel({
        userId: userId,
        label: this.remark,
      });
      this.$message({
        message: "设置成功",
        type: "success",
      });
      this.isShowLabel = false;
      this.getTableList();
    },
    async getShopOwnerList() {
      const { data } = await shopOwnerList(this.tableData);
    },
    //修改会员等级
    // async changeVipLevel(){
    //   const { data } = await modifyVipLevel({
    //     userId:this.vipId,
    //     levelId:this.vipLevel,
    //   });
    //   console.log(data)
    // },
    async toModify(status, list) {
      if (list.length < 1) {
        return this.$message.error("请选择需要操作的商品！");
      }
      let ids = list
        .map((item) => {
          return item.materialId;
        })
        .join(",");
      const { data } = await materialDelete({ ids });
      if (data.code == 0) {
        this.getTableList();
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
    },
    showApply(row) {
      this.editForm.applyId = row.applyId;
      this.editForm.phone = row.phone;
      this.editForm.idCard = row.idCard;
      this.editForm.addTime = row.addTime;
      this.editForm.trueName = row.trueName;
      this.editForm.referrerInfo = row.referrerInfo;
      this.dialogVisible = true;
    },
    async toApply(status) {
      let setData = {
        status,
      };
      if (status == 2 && this.editForm.remark == "") {
        return this.$message.error("请填写审核不通过原因");
      }
      setData.remark = this.editForm.remark;
      setData.applyId = this.editForm.applyId;

      const { data } = await shopownerApply(setData);
      console.log("编辑回调", data);
      if (data.code == 0) {
        this.hideVisible();
        this.getTableList();
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
    },
    // 开始修改排序
    onChangeSort(row) {
      row.inputVisible = true;
      this.tableData = [...this.tableData];
    },
    // 修改排序
    async handleInputConfirm(row) {
      const { data } = await changeSort({
        materialId: row.materialId,
        dept: Number(row.dept),
      });
      if (data.code !== 0) {
        this.$message({
          message: "设置失败",
          type: "error",
        });
      } else {
        this.$message({
          message: "设置成功",
          type: "success",
        });
      }
      row.inputVisible = false;
    },
    // 隐藏弹窗
    hideVisible() {
      this.dialogVisible = false;
      this.dialogVisible2 = false;
    },
    handleSelectionChange(arr) {
      this.multipleSelection = arr;
      console.log(this.multipleSelection);
    },
    handleSizeChange(num) {
      console.log(num);
      this.formData.pageSize = num;
      this.getTableList();
    },
    handleCurrentChange(num) {
      console.log("currentPage", num);
      this.formData.currentPage = num;
      this.getTableList();
    },
    onInput(value) {
      var reg = /^(0\.?\d{0,5}|[1-9]\d*\.?\d{0,5})$/;
      if (!reg.test(value)) {
        return this.$message({
          type: "error",
          message: "请输入正确的数字!",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.productList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .add {
    margin-top: 20px;
  }
  .el-table {
    margin-top: 50px;
  }
  .search_box {
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .btn {
      margin: 0 20px;
    }
    span {
      display: inline-block;
      margin-left: 20px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  /deep/.el-dialog__header {
    border-bottom: 1px solid #f1f1f1;
  }
  /deep/.el-dialog__footer {
    border-top: 1px solid #f1f1f1;
  }
  .btn_box {
    margin-top: 24px;
  }
  .modify_btn {
    padding-left: 10px;
    padding-right: 10px;
  }
  /deep/.popconfirm {
    margin-right: 10px;
  }
}
</style>